.main1 {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
}[dir] .main1 {
  background-color: #edf0f9;
}

.inner-div {
  position: relative;
  max-width: 91%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

[dir] .inner-div {
  padding-top: 100px;
  text-align: center;
  margin: 0 auto;
  background-color: white;
}

[dir=ltr] .inner-div {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

[dir=rtl] .inner-div {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

@media (max-width: 768px) {
  .inner-div {
    max-width: 100%;
  }
  [dir=ltr] .inner-div {
    padding-left: 10px;
    padding-right: 10px;
  }
  [dir=rtl] .inner-div {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 1024px) {
  .inner-div {
    max-width: 71%;
  }
  [dir=ltr] .get-started-logo {
    left: -150px !important;
  }
  [dir=rtl] .get-started-logo {
    right: -150px !important;
  }
}

@media (min-width: 1200px) {
  .inner-div {
    max-width: 61%;
  }
  [dir=ltr] .get-started-logo {
    left: -150px !important;
  }
  [dir=rtl] .get-started-logo {
    right: -150px !important;
  }
}

.mac {
  position: absolute;
  bottom: -40px;
}

[dir] .mac {
  margin-bottom: -20px;
}

[dir=ltr] .mac {
  right: 20px;
}

[dir=rtl] .mac {
  left: 20px;
}
.ipad {
  position: absolute;
}
[dir=ltr] .ipad {
  left: 40px;
}
[dir=rtl] .ipad {
  right: 40px;
}

@media (max-width: 768px) {
  .ipad {
    display: none;
  }
  .mac {
    display: none;
  }
}

.wrapper1 {
  z-index: 99;
  width: 100%;
  position: relative;
  top: 50px;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
}

[dir] .wrapper1 {
  margin: 0 auto;
  margin-bottom: -50px;
}

.wrapper2 {
  width: 100%;
  position: relative;
  top: 160px;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[dir] .wrapper2 {
  margin: 0 auto;
}
.get-started-logo {
  position: absolute;
  z-index: 11;
  top: 40px;
}
[dir=ltr] .get-started-logo {
  left: 50px;
}
[dir=rtl] .get-started-logo {
  right: 50px;
}
.ipad {
  width: 200px;
  height: 200px;
  z-index: 1;
}

.mac {
  width: 220px;
  height: 220px;
  z-index: 1;
}

@media (min-width: 1300px) {
  .mac {
    width: 260px;
    height: 260px;
  }
}

.h1 {
  font-size: 35px;
  font-weight: 900;
  color: black;
}

.h3 {
  max-width: 350px;
  font-size: 20px;
  color: black;
}

[dir] .h3 {
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

.logo1 {
  z-index: 99;
}

[dir] .logo1 {
  cursor: pointer;
}

@media (max-width: 768px) {
  .logo1 {
    width: 200px;
  }
}

.menu1 {
  z-index: 1;
  width: 40px;
}

[dir] .menu1 {
  cursor: pointer;
}

[dir] .button-signin {
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.button-signin:hover {
  color: white;
}

[dir] .button-signin:hover {
  background-color: #2a2a2a;
}

.last-section {
  width: 100%;
}

[dir] .last-section {
  margin-top: auto;
  text-align: center;
  padding: 30px 0 70px 0;
  background-color: #de2929;
}

[dir=ltr] .last-section {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

[dir=rtl] .last-section {
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

@media (max-width: 768px) {
  [dir] .last-section {
    padding: 30px 10px 70px 10px;
  }
}

.last-section-h1 {
  font-size: 35px;
  font-weight: 900;
  color: white;
}

.last-section-h3 {
  max-width: 330px;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

[dir] .last-section-h3 {
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

.button-trial {
  color: black !important;
}

[dir] .button-trial {
  margin: 0 auto;
  margin-top: 30px;
  background-color: white !important;
}

.button-trial:hover {
  color: white !important;
}

[dir] .button-trial:hover {
  background-color: #2a2a2a !important;
}

/* sign up page */
.signup-main {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
[dir] .signup-main {
  background: #edf0f9;
}

/* user set password page */
.signup-main-userpassword {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}
[dir] .signup-main-userpassword {
  padding-bottom: 502px;
  background-color: #edf0f9;
}

.signup-wrapper {
  display: flex;
  justify-content: space-between;
}

.signup-inner-div {
  max-width: 81%;
}

[dir] .signup-inner-div {
  text-align: center;
  padding: 30px 50px;
  margin: 0 auto;
  background-color: white;
}

[dir=ltr] .signup-inner-div {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

[dir=rtl] .signup-inner-div {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

@media (max-width: 768px) {
  .signup-inner-div {
    max-width: 100%;
  }
  [dir] .signup-inner-div {
    padding: 30px 30px;
  }
}

.signup-inner-div1 {
  max-width: 1200px;
}

[dir] .signup-inner-div1 {
  text-align: center;
  padding: 50px;
  padding-bottom: 100px;
  margin: 0 auto;
  background-color: white;
}

[dir=ltr] .signup-inner-div1 {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

[dir=rtl] .signup-inner-div1 {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.signup-h1 {
  font-size: 28px;
  font-weight: 900;
  color: black;
}

@media (max-width: 768px) {
  .signup-h1 {
    font-size: 20px;
  }
}

.signup-form {
  max-width: 550px;
}

[dir] .signup-form {
  margin: 0 auto;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .signup-form {
    max-width: 350px;
  }
  [dir] .signup-form {
    margin-top: 40px;
  }
  .signup-h1 {
    font-size: 32px;
  }
}

[dir] .eyeicon {
  cursor: pointer;
}

.signup-form1 {
  max-width: 400px;
}

[dir] .signup-form1 {
  margin: 0 auto;
  margin-top: 50px;
}

[dir] .signup-inputs {
  margin-top: 30px;
}

.signup-input {
  width: 100%;
  height: 60px;
  font-size: 20px;
  color: black;
}

[dir] .signup-input {
  border: 2px solid #edf0f9;
  border-radius: 5px;
  padding: 0 10px 0 10px;
}

[dir] .eye-icon {
  cursor: pointer;
}

.signup-input::placeholder {
  color: grey;
  font-weight: 500;
}

.signup-input:focus-visible {
  outline: none;
}

.checkbox1 {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
}

[dir] .checkbox1 {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .checkbox1 {
    flex-wrap: wrap;
  }
}

.checkbox-size {
  width: 1.5rem;
  height: 1.5rem;
}

[dir] .checkbox-size {
  cursor: pointer;
}

.signup-h3 {
  font-size: 15px;
  color: black;
}

.button-signup {
  color: white;
  width: 100%;
}

[dir] .button-signup {
  margin: 0 auto;
  margin-top: 40px;
}

/* signup form step 2 */

.signupstep2-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

[dir] .signupstep2-inputs {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .signupstep2-inputs {
    flex-direction: column;
  }
  .signupstep2-inputs > div {
    width: 100%;
  }
}

.back-to-signup {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

[dir] .back-to-signup {
  cursor: pointer;
}

.signin-step2-h3 {
  font-size: 18px;
  font-weight: 900;
  color: black;
}

@media (max-width: 768px) {
  .signin-step2-h3 {
    font-size: 10px;
  }
  .back-to-signup {
    display: flex;
    align-items: center;
  }
}

.button-signup-step2 {
  width: 100%;
  height: 40px;
}

[dir] .button-signup-step2 {
  margin: 0 auto;
  margin-top: 50px;
}

.backarrow {
  width: 35px;
  height: 35px;
}

[dir] .backarrow {
  cursor: pointer;
}

.error {
  height: 24px;
  color: red;
}

[dir] .error {
  padding: 0 5px 0 5px;
  margin-top: 5px;
}

[dir=ltr] .error {
  text-align: left;
}

[dir=rtl] .error {
  text-align: right;
}

/* faq classes */

.faq {
  width: 50%;
}

[dir] .faq {
  margin: 0 auto;
}

.faq-input {
  height: 10rem !important;
}

.faq-button {
  min-width: 20% !important;
  width: 20% !important;
}

[dir] .faq-button {
  margin: 0 auto !important;
}

/* dropdown menu */
.dropdown-item {
  width: 100% !important;
}

/* moreVertical icon */
.morevert-icon {
  color: black !important;
}

/* faq title */
.card-title {
  text-transform: capitalize !important;
}

/* dashboard trial banner */
.trial-banner {
  width: 100% !important;
  font-size: 1.2rem !important;
  color: #bf2d2d !important;
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
  gap: 0.5rem !important;
}
[dir] .trial-banner {
  margin: 1rem 0 1.8rem 0 !important;
  padding: 1rem !important;
  background: white !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.trial-span {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
.modal-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 99999;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir] .modal-wrapper {
  background: rgba(0, 0, 0, 0.6);
}
[dir=ltr] .modal-wrapper {
  right: 0;
  left: 0;
}
[dir=rtl] .modal-wrapper {
  left: 0;
  right: 0;
}

.modal-wrapper-subscribe {
  position: fixed;
  top: 50px;
  z-index: 99999;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir=ltr] .modal-wrapper-subscribe {
  right: 0;
  left: 0;
}

[dir=rtl] .modal-wrapper-subscribe {
  left: 0;
  right: 0;
}

.modal-wrapper-subscribe-loggedin {
  position: fixed;
  width: 1000px;
  height: 300px;
  top: 200px;
  z-index: 99999;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir] .modal-wrapper-subscribe-loggedin {
  background: white;
}

[dir=ltr] .modal-wrapper-subscribe-loggedin {
  right: 0;
  left: 500px;
}

[dir=rtl] .modal-wrapper-subscribe-loggedin {
  left: 0;
  right: 500px;
}

/* terms and privacy policy classes */

.terms-privacy-main {
  width: 1200px;
}

[dir] .terms-privacy-main {
  margin: 100px auto;
  background: white;
  border-radius: 20px;
  padding: 50px 100px;
}

@media (max-width: 768px) {
  .terms-privacy-main {
    width: 320px;
  }
  [dir] .terms-privacy-main {
    padding: 50px 30px;
  }
}

.card-container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir] .card-container {
  margin: 20px 0px;
  border-radius: 10px;
}

.card-elementss {
  height: 44px;
}

[dir] .card-elementss {
  border-radius: 10px;
  background-color: #f3f2f7;
  padding: 10px;
}

/* Add card PopUp */

.add-card-main {
  width: 1500px;
}

[dir] .add-card-main {
  background: white;
  border-radius: 20px;
  padding: 20px 40px;
  text-align: center;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .add-card-main {
    width: 285px !important;
  }
  [dir] .add-card-main {
    padding: 20px 20px !important;
  }
  [dir] .add-card-h1 {
    padding: 20px !important;
  }
}

.add-card-h1 {
  color: black;
  font-weight: 900;
  font-size: 22px;
}

[dir] .add-card-h1 {
  padding: 20px;
  margin-bottom: 30px;
}

.content-wrapper {
  z-index: 11;
}
